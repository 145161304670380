import React from "react";
import PropTypes from "prop-types";
import { graphql, StaticQuery } from "gatsby";
import { isLangEn } from "../helpers/localization";

import styles from "./News.module.scss";

const News = ({ data }) => {
  const { edges: postsList } = data.allMarkdownRemark;
  const postsLastThree = postsList.slice(0, 3);

  return (
    <ul className={styles.news}>
      {postsList &&
        postsLastThree.map(({ node: post }) => (
          <li className={styles.item} key={post.id}>
            <p className={styles.title}>
              {isLangEn()
                ? post.frontmatter.newsEn.title
                : post.frontmatter.newsEl.title}
            </p>
            <p className={styles.description}>
              {isLangEn()
                ? post.frontmatter.newsEn.description
                : post.frontmatter.newsEl.description}
            </p>
          </li>
        ))}
    </ul>
  );
};

News.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default () => (
  <StaticQuery
    query={graphql`
      query BlogRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 10000)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                date(formatString: "MMMM DD, YYYY")
                newsEn {
                  title
                  description
                }
                newsEl {
                  title
                  description
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <News data={data} count={count} />}
  />
);
